/* WeOperate.css */
.we-operate-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-family: Acta Display W01 Medium;
  background-color: #c7cacf;
   /* Adjust padding for smaller screens */
   padding-left: 100px;
    padding-right: 100px;
}

.columns {
  flex: 1;
  text-align: center;
 
}

/* Optional: Add styling for the text or customize as needed */
.columns button {
  margin: 0;
  padding: 10px; /* Adjust padding for the button */
  font-size: 18px;
  /* text color */
  color: black;
  /* Background color of the text four columns */
 background-color: transparent ;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  width: 150px;
  height: 65px;
  font-weight: 600;
  border-radius: 5px;
}

/* Hover effect */
.columns button:hover {
  background-color: #ae8210; /* Adjust the background color on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition effect */
  transform: translateX(5px);
  
}

.columns button {
  background-color: #5d2303; /* Adjust the background color on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition effect */
  transform: translateX(5px);
  color: white;
  font-size: 18px;
}


.button {
  transition: transform 0.3s ease; /* Add transition effect */
}

/* Remove the hover effect when not in hover state */
.button:not(:hover) {
  transform: translateX(0);
}

.columns1 {
  color: black;
  font-size: 1.5em;
  margin: 0.83em 0;
}

.columns1 {
  font-size: 40px;
}

/* Add gap between Residential and Retail */
.gap {
  margin-bottom: 10px; /* Adjust the value for the desired gap */
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .we-operate-container {
    flex-direction: columns;
    padding: 0px; /* Adjust padding for smaller screens */
  }

  .columns {
    flex: 100%;
    flex-direction: row;
  }
  .columns button {
   font-size: 15px;
  }
  
}


/* Your existing styles */

/* Mobile styles */
@media (max-width: 768px) {
.body{
  padding: 0;
  margin: 0;
}
.we-operate-container {
  text-align: center;
  padding: 0px; 
  margin: 0px;
}
.columns {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjust as needed for spacing */
  align-items: center;
  padding-top: 2%;
  margin: 0;
  padding-bottom: 20px;
}
.columns1{
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.columns > div {
  margin-right: 10px; /* Adjust as needed for spacing between items */
}
.button{
  padding: 0px;
}
.gap {
  margin-bottom: 0px; /* Adjust the value for the desired gap */
}
}

@media (min-width:850px) and (max-width:992px) {
  .columns1 h2 {
    font-size: 20px;
  }
  
  
  
}

@media (min-width:820px) and (max-width:1200px) {
  
  .columns button {
   
   
    
    width: 90%;
    height: 65px;
    font-weight: 600;
    border-radius: 5px;
   
  }
  .we-operate-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-family: Acta Display W01 Medium;
    background-color: #c7cacf;
     /* Adjust padding for smaller screens */
     padding-left: 40px;
      padding-right: 40px;
  }
}


