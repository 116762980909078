/* Weturn.css */

/* Existing styles for larger screens (desktop) */

.weturn-container {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;
  background: #f9f7ef;
  padding-top: 20px;
}

.weturn-title {
  text-align: center;
  margin-bottom: 28px;
  text-align: left;
  color: #511527;
}

.weturn-title h1 {
  font-size: 28px;
}

.weturn-para {
  margin-bottom: 30px;
  color: #511527;
  font-size: 18px;
}

.weturn-boxes {
  display: flex;
  justify-content: space-between;
}

.weturn-box {
  width: 30%;
  perspective: 1000px;
  position: relative;
}

.weturn-box-inner {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.weturn-box:hover .weturn-box-inner {
  transform: rotateY(180deg);
}

.weturn-box-front,
.weturn-box-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.weturn-box-front {
  display: flex;
  align-items: center;
  justify-content: center;
}

.weturn-box-front img {
  max-width: 100%;
  height: auto;
}

.weturn-box-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  border: 2px solid #d8c306;
}

.weturn-box-back p {
  margin-bottom: 10px;
  color: #511527;
  font-size: 18px;
}

.weturn-box-back h3 {
  margin-bottom: 10px;
  color: #511527;
  font-size: 30px;
}

.custom-divider-weturn {
  height: 3px; /* Adjust the height as needed */
  width: 200px;
  background-color: #423e18bc; /* Specify your desired color */
  margin: 20px 0; /* Adjust the margin as needed */
}
.pb{
  margin-bottom: 20px;
}

/* Responsive styles for smaller screens (mobile) */

@media (max-width: 768px) {
  .weturn-container {
      padding-left: 20px;
      padding-right: 20px;
      /* margin-top: 100px; */
      
  }
  .weturn-box {
      width: 100%;
      margin-bottom: 20px;
  }
}



/* Existing styles for larger screens (desktop) */
/* ... (your existing styles) ... */

/* Responsive styles for smaller screens (mobile) */
@media (max-width: 768px) {
  .weturn-container {
      padding-left: 20px;
      padding-right: 20px;
  }

  .weturn-boxes {
      flex-direction: column; /* Stack boxes in a column */
      align-items: center; /* Center items in the column */
      width: 100%; /* Make each box take up full width */
      margin-bottom: 20px;
  }

}
