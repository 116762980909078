/* YourSpace.css */

.container {
  display: flex;
  font-family: Acta Display W01 Medium;
}

.leftSection {
  flex: 1;
}

.rightSection {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.rightTop {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: justify;
  overflow-y: auto;
}

.rightBottom {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.fullImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}
.yourspace-images{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 20%;
  width: 100%;
  height: auto;
}

.img-1{
  transition: margin-left 0.5s ease;
  width: 54%;
  height: auto;
}
.img-2{
  padding-left: 5%;
  width: 100%;
  height: auto;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 768px) {
  .container-yourspace {
    margin-top: 70px;
    flex-direction: column; /* Adjust margin for smaller screens */
  }

  .rightTop {
    border-bottom: none; /* Remove border on small screens */
    /* margin-top: 100px; */
  }
  .images{
    display: flex;
    flex-direction: column;
  }
  .img-1{
    flex: 0 0 auto;
    width: 100%;
  }
  .img-2{
    display: none;
  }

}

/* TechFore images */

.abt-img-div {
  margin: 35px 0px;
  padding: 0px;
}
@media (min-width: 768px){
.order-md-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
*, ::after, ::before {
  box-sizing: border-box;
}


/* user agent stylesheet */
div {
  display: block;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

/* Vision and Mission */
.row {
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
margin-top: calc(-1 * var(--bs-gutter-y));
margin-right: calc(-.5 * var(--bs-gutter-x));
margin-left: calc(-.5 * var(--bs-gutter-x));
}
*, ::after, ::before {
box-sizing: border-box;
}
/* user agent stylesheet */
div {
display: block;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
width: 100%;
padding-right: calc(var(--bs-gutter-x) * .5);
padding-left: calc(var(--bs-gutter-x) * .5);
margin-right: auto;
margin-left: auto;
}