.project {
    display: grid; /* Use grid layout for project boxes */
    place-items: center; /* Center the content inside each box */
    width: 100%;
    height: 290px; /* Set a fixed height for the project boxes */
  }
  
  .project img {
    max-width: 100%;
    height: 100%; /* Set height to 100% to fill the entire box */
    object-fit: cover; /* Cover the entire box while maintaining aspect ratio */
  }
  
  
  .project p {
    margin-top: 10px;
    text-align: center;
   
  }
  
  /* Mobile Responsive Code */
  @media (max-width: 768px) {
    .architectural-design-page {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      position: static;
      height: auto;
      overflow-y: visible;
    }
  
    .content {
      width: 100%;
      margin-left: 0;
      padding: 10px;
      overflow-y: visible;
    }
  
    .projects-grid {
      grid-template-columns: 1fr;
    }
    .project{
      border: 3px solid black;
    }
  }