/* Updated CSS */
.service-component-heading {
  font-family: 'Acta Display W01 Medium';
 
  padding: 100px 80px;
  padding-bottom: 0px;
  overflow-x: hidden;
  
}
.sub-heading{
  font-size: 40px;
  color: #5d2303;
}

.service-row {
  display: flex;
  margin-top: 10px;
 border-bottom: 3px dashed #5d2303;

}

.service-column {
  padding: 10px;
}

.number-column {
  flex: 1;
  font-size: 20px;
  color: #5d5954;
}

.title-column {
  flex: 2;
  font-size: 20px;
}

.logo-column {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-column img {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.description-column {
  flex: 7;
  font-size: 20px;
}

.learn-more-text {
 display: flex;
 justify-content: center;
 

 
}

.learn-more-text a {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: #5d2303;
  margin-top: 50px;
}
.title-column {
  flex: 2;
  font-size: 23px;
  font-weight: bold;
  text-align: left; /* Add this line to center-align the titles */
}

.learn-more-text  :hover {
 color: rgb(255, 140, 0);
}

@media (max-width: 768px) {
  .service-component-heading {
    padding: 20px;
    padding-top: 92px;
  }
  .learn-more-text a {
    
    margin-top: 0px;
  }

  .service-row {
    flex-direction: column;
  }

  .number-column,
  .title-column,
  .logo-column,
  .description-column {
    flex: auto;
    padding: 10px 0;
    text-align: center;
  }

  .number-column {
    font-size: 20px;
  }

  .title-column {
    font-size: 16px;
  }

  .logo-column img {
    height: 60px;
    width: 60px;
  }
  .title-column {
   font-size: 23px;
    text-align: center; /* Add this line to center-align the titles */
  }
 
}

.sub-heading{
  text-align: center;
}

.learn-more-text {
  text-align: left; /* Align the "Read More" text to the left */
  margin-top: 0px;
}

.service-paragraph{
  text-align: justify;
} 
.read-more-column{

}

.read-more-column {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
}

.read-more-button {
  background-color: #c7cacf; /* Button background color */
  color: #000000; /* Button text color */
  border: none; /* Remove button border */
  padding: 10px 15px; /* Padding for button */
  cursor: pointer; /* Change cursor to pointer */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  text-decoration: none; /* Remove default underline */
  display: flex; /* Make it a flex container */
  align-items: center; /* Center align items vertically */
 font-size: 15px;
 font-weight: bold;
}

.read-more-button:hover {
  background-color: #d6ac12; /* Darker background color on hover */
  color: #5d2303; /* Change text color on hover */
}

.read-more-button i {
  margin-left: 10px; /* Add margin to the right of the icon */
}


@media (max-width: 768px) {
  .learn-more-text {
    text-align: center; /* Align the "Read More" text to the left */
  }
  .service-paragraph{
    text-align: justify;
  }
  .sub-heading{
    font-size: 25px;
  }
}

@media (min-width:850px) and (max-width:992px) {
  .service-component-heading {
    font-family: 'Acta Display W01 Medium';
    padding: 100px 80px;
    padding-bottom: 400px;
    overflow-x: hidden;
}
}


