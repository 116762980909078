/* ProjectModal.css */

.project-modal-overlay {
  margin-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  backdrop-filter: blur(50px); /* Apply blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure modal is on top of other content */
}

.project-modal {
  position: relative;
  background-color: rgba(150, 142, 142, 0); /* Transparent light black color */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw; /* Adjust width as needed */
  height: 90vh; /* Adjust height as needed */
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  backdrop-filter: none; /* Initial state: no blur effect */
}

.project-modal.active {
  backdrop-filter: blur(50px); /* Apply blur effect when modal is active */
}

.carousel {
  flex: 1; /* Take remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel img {
  max-width: 100%;
  max-height: 80vh; /* Adjust maximum height as needed */
  object-fit: contain;
  margin-bottom: 20px;
}

.prev-button,
.next-button,
.close-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: rgb(32, 31, 31);
  z-index: 1000; /* Ensure buttons are on top of carousel */
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
  color: #fff; /* White text color */
  border: none;
  border-radius: 50%; /* Circular shape for buttons */
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}


.prev-button {
  left: 30px;
  color: rgb(32, 31, 31);
  font-size: 50px;
}

.next-button {
  right: 30px;
  color: rgb(32, 31, 31);
  font-size: 50px;
}

.close-button {
  top: 30px;
  right: 40px;
  color: rgb(32, 31, 31);
  font-size: 40px;
}

/* Disable outline on button focus */
.prev-button:focus,
.next-button:focus,
.close-button:focus {
  outline: none;
}

/* Media Query for mobile devices */
@media (max-width: 768px) {
  .project-modal {
    padding: 10px;
    
  }

  .carousel img {
    max-height: 60vh; /* Adjust maximum height for mobile */
    margin-bottom: 0px;
  }
  .project-modal-overlay {
    margin-top: 10%;
    position: fixed;
   
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure modal is on top of other content */
    
  }
  .prev-button {
    margin-top: 60%; /* Push to the top of remaining space */
    font-size: 30px;
  left: 10px;
  }

  .next-button {
    margin-bottom: auto; /* Push to the bottom of remaining space */
    font-size: 30px;
    margin-top: 60%; 
    right: 10px;

    
  }
  .project-modal {
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Adjust width as needed */
    height: 80vh; /* Adjust height as needed */
    margin-bottom: 100px;
    
  }
  .close-button {
    font-family: Acta Display W01 Medium;
    font-size: 30px;
    color: rgb(32, 31, 31);
    right: 10px;
    top: 60px;
   
  }
 
  
}
.modal-open {
  overflow: hidden;
}




















