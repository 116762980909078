/* ContactForm.css */


.contact-container {
  font-family: Acta Display W01 Medium;
  padding: 20px;
}

.career-section {
  margin-top: 70px;
  background-color: #f9f7ef;
  width: 100%;
  padding:50px;
  font-family: Acta Display W01 Medium;
}

.joining-heading{
  color: #511527;
  font-size: 30px;
  padding-left: 10%;
    padding-right: 10%;
}

.joining-para{ 
  color: black;
  font-size: 18px;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
    width: 100%;
  margin-top: 30px;
  font-size: 18px;
    line-height: 1.7em;
}



#your-story-section {
  text-align: center;
}

#your-story-section h2 {
  display: inline-block;
  background-color: #7d6741; /* Set your desired background color */
  padding: 20px;
  margin: 0;
  color: white; /* Set your desired text color */
  font-size: 18px;
}





#join-section p {
  margin-bottom: 0;
}

#your-story-section {
  color: white;
  padding: 20px;
  text-align: center;
}

.your-story-box {
  width: 200px;
  height: 200px;
  background-color: white;
  /* Additional styling for the box */
  margin: 0 auto;
}

#form-section {
  background-color: #f9f7e7;
  padding: 20px;
  border: 1px solid #ccc;
  width: 80%;
  margin-left: 150px;
}

.form-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if the screen size is small */
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-group {
  flex: 1;
  margin-right: 10px; /* Add space between form groups */
  
}

label {
  display: block;
  margin-bottom: 5px;
  
}

input,
select {
  width: 100%; /* Use full width for inputs and selects */
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.custom-primary-buttonsend{

 
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  width: 20%;
  
  margin: 0 auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0; /* Remove margin between form groups on small screens */
  }
  
}
.label-group {
  display: flex;
  align-items: center; /* Vertically center the label and file input */
}

.form-group{
  display: flex;
  display: row;
}

/* ContactForm.css */

/* ... Your existing styles ... */

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

.label,
input,
select,
textarea {
  width: 100%; /* Make text area take full width */
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* ContactForm.css */

/* ... Your existing styles ... */

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  
}

.form-group {
  flex: 1;
  margin-right: 50px;
}

/* Increase the margin between input elements */
input {
  margin-right: 10px;
}

.label,
select,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* ... Your existing responsive styles ... */


@media (max-width: 768px) {
.careerpage{
  width: 95%;
  padding: 0px;
  margin: 0px;
  margin: 0 auto;
}


#join-section{
padding: 0px;
padding-top: 20px;
padding-bottom: 20px;
text-align: justify;
}

.career-section h2{
  text-align: left;
}

  .form-row {
    flex-direction: column;
    padding: 0px;
   
    
  }

  .form-group{
    padding: 10px;
    margin-right: 0px;
   }

  input {
    margin-right: 0;
  }

  #form-section{
    margin-left: 0;
    width: 100%;
    margin-right: 0px;
  }
  .custom-primary-buttonsend{
    background-color: #511527;
    color:white ;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    width: 50%;
  }
  #form-section.section{
    padding: 10px;
    margin: 0px;
  }
 .contact-container{
  margin: 0px;
  padding: 5px;
 }
 #join-section.section{
 
padding: 0px;
 }
 .new-class-name{
  width: 100%;
  
 }

 
}

#cvUpload{
  border: 1px solid black;
  width: 53%;
}
.upload-description{
 
  width: 150px;
  
  
  margin-top:15px;
}

#role{
  margin-right: 20px;
}

/* thank you message box style */

.thank-you-message {
  text-align: center;
}

.thank-you-message i {
  color: green;
  font-size: 50px;
}

.thank-you-message p {
  margin-top: 10px;
  font-size: 24px; /* Adjust font size */
}

@media (min-width:850px) and (max-width:992px) {
  #form-section {
    background-color: #f9f7e7;
    padding: 100px;
    border: 1px solid #ccc;
    width: 95%;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  .form-row {
    flex-direction: column;
    
  }

  .form-group{
    padding: 10px;
    margin-right: 0px;
   }

  input {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
 
  .form-group {
    padding-left: 20px;
  }
}
#join-section a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the color from its parent element */
  color: blue;
}

#join-section a:hover {
  text-decoration: underline; /* Adds underline on hover if needed */
}
