.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create 4 columns of equal width */
    grid-gap: 20px; /* Adjust spacing between items */
    margin: 0 auto; /* Center the grid horizontally */
    margin-top: 105px;
  }
  
  .grid-item {
    position: relative;
    overflow: hidden; /* Prevent text from overlapping images */
    margin: 10px;
  }
  
  .grid-item img {
    width: 100%;
    height: auto; /* Adjust image height as needed */
    object-fit: cover; /* Maintain aspect ratio */
  }
  
 .grid-item-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0); /* Initially transparent */
  color: white;
  text-align: center;
  transition: background-color 0.3s ease-in-out; /* Smooth opacity transition */
}

.grid-item:hover .grid-item-text {
  background-color: rgba(0, 0, 0, 0.5); /* Show background on hover */
  transform: translateY(-50%);
  transition: 0.4s;
  width: 100%;
    text-align: center;
    position: absolute;
    top: 90%;
}
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr); /* Change to 2 columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr; /* Change to 1 column on even smaller screens */
    }
  }
  