/* ProductiveTalk.css */

.productive-talk-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: #f9f7ef;
    font-family: 'Acta Display W01 Medium';
    padding-bottom: 1%;
}

.sub-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.productivetalkleft-box {
    flex: 0.7; /* Change from flex: 1; to flex: 0.4; */
    background-color: white;
    padding: 20px 20px;
    box-shadow: 0 0 10px rgba(172, 134, 134, 0.7);
    border-radius: 5px;
    margin-right: 40px;
}


.productivetalk-para {
    text-align: justify;
    font-family: 'Acta Display W01 Medium';
    font-size: 18px;
}

.form-box {
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(172, 134, 134, 0.7);
    border-radius: 5px;
    background-color: #f9f9f9;
   
    margin: 0 auto;
}
.whole-form{
    width: 70%;
    margin: 0 auto;
}

.form-box input[type="text"],
.form-box input[type="email"],
.form-box textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}

.primary-btn {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: green;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.productivetalkright-box {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productivetalktitle {
    font-size: 30px;
    color: #511527;
}

.productivetalkrighttitle {
    font-size: 42px;
    color: #511527;
    background-color: #f9f7ef;
}
.customes-divider {
    margin-top: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
    border-bottom: 2px solid #511212; /* Divider color */
    width: 20%; /* Width of the divider */
}


@media (max-width: 786px) {
    .productive-talk-container {
        flex-direction: column;
    }

    .sub-container {
        flex-direction: column;
        width: 100%;
        padding: 0px;
    }

    .productivetalkleft-box,
    .productivetalkright-box {
        width: 100%;
        margin: 0;
        padding: 0px;
    }

    .productivetalktitle {
        font-size: 24px;
    }

    
    .productivetalkrighttitle {
        font-size: 30px;
    }

    .productivetalkright p {
        font-size: 12px;
    }
    .productivetalkleft-box {
        width: 100%;
        padding: 5px 2px;
        
        margin-right: 0px;
    }
    .whole-form{
        width: 85%;
    }
    .productivetalkright-box {
       
        padding: 20px;
    }
}


@media (min-width:786px) and (max-width:1200px) {
    .productive-talk-container {
        
    }

    .sub-container {
       
        width: 80%;
        
    }

    .productivetalkleft-box,
    .productivetalkright-box {
        width: 100%;
        margin: 0;
        padding: 0px;
    }

    .productivetalktitle {
        font-size: 24px;
    }

    
    .productivetalkrighttitle {
        font-size: 30px;
    }

    .productivetalkright p {
        font-size: 12px;
    }
    .productivetalkleft-box {
        width: 100%;
        padding: 5px 2px;
        height: 80%;
        
        margin-right: 0px;
    }
    .whole-form{
        width: 85%;
    }
    .productivetalkright-box {
       
        padding: 20px;
    }
}