/* Navbar.css */

* {
  box-sizing: border-box;
}

:root {
  --mainColor: rgb(47, 45, 45);
  --secondaryColor: #db2b39;
  --textColor: #eee;
}
.nav-item a{
 
  font-size: 18px;
 
  color: #000080; /* Navy Blue color code */
  font-weight: 800;
  
}

nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  transition: transform 0.3s ease, background-color 0.3s ease; /* Add smooth transitions */
 
  
 
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  transition: color 0.3s ease;
;
  
}


nav a:hover {
  color: var(--secondaryColor);
}

nav .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  font-size: 1.5rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Updated styles for smooth opening animation */
nav .responsive_nav {
  transform: translateY(0);
}

/* Add this style to your Navbar.css file */
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000; /* Ensure the navbar appears above other content */
  transition: margin-top 0.3s ease; /* Add smooth transition */
 

}

/* Adjust the margin-top based on your actual navbar height */
.navbar.active {
  margin-top: 0;
  background-color: var(--mainColor); /* Background color when menu is open */
  
}

/* Default styling for larger screens */
.navbar-brand img {
  width: 250px; /* Default width */
  height: 70px; /* Default height */
  padding: 0px;
}

.container-fluid{

  font-family: Acta Display W01 Medium;
  background-color: transparent;
}

/* Media query for screens up to 767px wide (typical mobile screens) */
@media only screen and (max-width: 767px) {
  .navbar-brand img {
    width: 200px; /* Adjusted width for mobile screens */
    height: 100px; /* Adjusted height for mobile screens */
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
  }


  .navbar-nav a {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ddd; /* Add a border at the bottom of each menu item */
    padding-bottom: 10px; /* Optional padding to separate items visually */
    
  }

  /* Underline effect below the text on hover */
  .navbar-nav a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
   
    transform: scaleX(0); /* Initially set to no width */
    transform-origin: right;
    transition: transform 0.3s ease; /* Add smooth transition for underline */
  }

  /* Scale up the underline on hover */
  .navbar-nav a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}


/* Navbar.css */

/* ... Your existing styles ... */

/* Media query for screens up to 991px wide (typical tablet screens) */
@media only screen and (max-width: 991px) {
  .navbar-nav a {
    margin: 1rem 0f;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ddd; /* Add a border at the bottom of each menu item */
    padding-bottom: 10px; /* Optional padding to separate items visually */
  }
}

/* Media query for screens up to 767px wide (typical mobile screens) */
@media only screen and (max-width: 767px) {
  .navbar-nav a {
    border-bottom: none; /* Remove the border for smaller screens */
    
  }
  .navbar-nav li{
   
    width: 30%;
  }

  .nav-item a{
    text-align: center;
    
  }

  .div.container-fluid{
    padding: 0px;
   
  }
  
  
}

.container-fluid{
  font-family: Acta Display W01 Medium;
  background-color:#c7cacf;
}


/* .navbar{
background-color: #7a297a; 

} */



/* Navbar.css */
.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
}



/* Navbar.css */
.homepage-navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
 

}

.scrolled.homepage-navbar {
  /* background-color:#ffffff; */
  background-color: #2c2b07; 

}



.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000; /* Ensure the navbar appears above other content */
  transition: margin-top 0.3s ease; /* Add smooth transition */
  background-color: white; /* Set navbar background to white */
 
}

.navbar.active {
  margin-top: 0;
  background-color: var(--mainColor); /* Background color when menu is open */
}


/* Navbar.css */

/* Keep the existing styles for transparent navbar */
.homepage-navbar {
  background-color: rgba(255, 255, 255, 0.2); /* Adjust the last value (opacity) as needed */

  transition: background-color 0.3s ease;
 
}

.scrolled.homepage-navbar {
  /* background-color: #ffffff; */
  background-color: #7a297a; 
  color: white;

}

/* Update the background color of the toggle menu button */
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000; /* Ensure the navbar appears above other content */
  transition: margin-top 0.3s ease; /* Add smooth transition */
}

.navbar.active {
  margin-top: 0;
  background-color: white; /* Background color when menu is open */
}

/* Set background color of toggle menu button */
.navbar.active .navbar-toggler {
  background-color: white; /* Set toggle menu button background color to white */
}

/* Keep the existing styles for other elements */
/* ... */


/* added hover effect in navbar items  */

.navbar .container-fluid{
  padding: 0px;
  padding-right: 100px;
  padding-left: 100px;
}

.nav-item .nav-link {
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-item .nav-link:hover {
  transform: scale(1.5); /* Scale up on hover */
  color: #ff5733; /* Change text color on hover */
}

/* added hover effect to logo */

.navbar-brand img {
  transition: transform 0.3s ease-in-out;
}

.navbar-brand img:hover {
  transform: scale(1.1); /* Scale up on hover */
}


/* Hover effect for navbar toggler */
.navbar-toggler:hover {
  transform: scale(1.1);
}

/* Hover effect for close toggle menu button (cancel button) */
.navbar-toggler[type="button"]:hover {
  background-color: #ccc; /* Change background color on hover */
  
}

@media (max-width: 767px) {
  .navbar .container-fluid {
    padding-right: 0;
    padding-left: 0;
    background-color: rgb(238, 234, 234);
      font-family: Acta Display W01 Medium;
      background-color:#c7cacf;
  }
  .navbar-brand img {
    width:200px; /* Default width */
    height: 63px; /* Default height */
    padding: 0px;
    margin-left: 20px;
  }
  .navbar-toggler{
    margin-right: 20px;
  }
  
}





/* style for make navbar toggler */



/* Navbar.css */













/* Media query for screens up to 767px wide (typical mobile screens) */
@media only screen and (max-width: 767px) {
 
  .navbar-toggler {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 90%; /* Adjust width as needed */
    max-width: 75%; /* Adjust max-width as needed */
    background-color: #f3f2ef;
    padding-top: 60px;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    border: 1px solid black;
  }
  .container-fluid li{
    /* border-top: 2px  solid red; */
    width: 100%;
    margin-bottom: 5px;
    text-decoration: none;
    background-color: rgb(243, 240, 245);
        
  }
  

  .navbar-collapse.show {
    transform: translateX(0);
  }

  .navbar-toggler svg {
    color: #000;
    font-size: 24px;
  }

  .navbar-toggler.active svg {
    color: #5d2303;
  }
  .nav-item .nav-link:hover {
    transform: scale(1); /* Scale up on hover */
  }
 #li-1{
  margin-top: 5px;
 }
  
  
  
  
}


 
.active-link {
  color: #5a3904;
  font-size: 1em;
  background-color: #eeeee4;
  border-radius: 10%;
}

@media (min-width:850px) and (max-width: 992px){
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
}
}
