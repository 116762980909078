/* ArchitecturalDesignPage.css */
.architectural-design-page {
  display: flex;
  margin-top: 80px;
  font-family: 'Acta Display W01 Medium';

}

.sidebar {
  width: 20%;
  padding: 20px;
}
.sidebar  a{
font-weight: bold;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

.sidebar li.active {
  background-color: #ccc;
}

.content {
  width: 80%;
  padding: 20px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust box width and allow auto-fill */
  gap: 20px; /* Set gap between grid items */
}

.project {
  position: relative;
  width: 100%;
  height: 100%; /* Ensure the project box occupies the entire container */
  overflow: hidden; /* Ensure image does not overflow its container */
  border-radius: 10px;
  border: 5px solid black; /* Add border */
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
}

.project img {
  width: 100%; /* Ensure image fills entire container */
  height: 100%; /* Ensure image fills entire container */
  object-fit: contain; /* Crop image to fill container */
  
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 1; /* Always visible */
 
}

.project p {
  text-align: center;
  font-family: Acta Display W01 Medium;
  

}


.project .project-overlay {
  opacity: 1;
}



/* Mobile Responsive Code */
@media (max-width: 768px) {
  .architectural-design-page {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    height: auto;
    overflow-y: visible;
  }

  .content {
    width: 100%;
    padding: 10px;
    overflow-y: visible;
  }
}
