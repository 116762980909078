.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #5d2303;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.4s, transform 0.2s; /* Adding transition for transform */
  }
  
  .scroll-to-top.show {
    opacity: 1;
  }
  
  .scroll-to-top:hover {
    transform: scale(1.1); /* Scale up the button on hover */
  }
  
  .scroll-to-top i {
    font-size: 20px;
  }
  