/* Common styles for both desktop and mobile */
.asinterior-container {
    display: flex;
    background: #f9f7ef;
    font-family: Acta Display W01 Medium;
    padding-left: 100px;
    padding-right: 100px;
}

.asinteriorleft-side {
    flex: 1;
    padding: 20px;
}

.asinteriorleft-side h5 {
    font-size: 13px;
    color: black;
    margin-top: 10px;
}

.asinteriorright-side {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.asinteriorright-side-content {
    margin-bottom: 20px;
    font-size: 18px;
    color: #511527;
    font-weight: 500;
}

.know-more-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

.know-more-button:hover {
    background-color: #2980b9;
}

.primary-btnknowmore {
    display: inline-block;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #c7cacf;
    color: #fff;
    border-radius: 5px;
    margin: -5px 7px 0px 0px;
    width: 30%; /* Full width for desktop */
}

.primary-btnknowmore span {
    width: 90px;
    height: 20px;
    color: black;
    font-size: 18px;
}

.primary-btnknowmore::after {
    content: '\00a0→';
    margin-left: 10px;
    color: #000;
}

.primary-btnknowmore:hover {
    background-color: #bb9c21;
}


@media (min-width:850px) and (max-width:992px) {
  

.primary-btnknowmore {
    display: inline-block;
    padding: 12px 0px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #c7cacf;
    color: #fff;
    border-radius: 10px;
    margin: 0px 15px 70px 3px;
    width: 55%; /* Full width for desktop */
}

.primary-btnknowmore span {
    width: 90px;
    height: 20px;
    color: black;
    font-size: 18px;
}

.primary-btnknowmore::after {
    content: '\00a0→';
    margin-left: 10px;
    color: #000;
}

.primary-btnknowmore:hover {
    background-color: #bb9c21;
}
}



/* Mobile responsiveness */
@media only screen and (max-width: 768px) {
    .asinteriorright-side,
    .asinteriorleft-side {
        flex: 1; /* Equal width for mobile */
        width: 100%; /* Full width for mobile */
        order: 2; /* Change the order for mobile */
    }
    
    .asinteriorright-side {
        padding: 20px;
    }

    .primary-btnknowmore {
        width: 100%; /* Full width for mobile */
    }

    .left-title {
        font-size: 30px; /* Adjusted font size for mobile */
    }
}

.left-title{
    font-size: 40px;
    color: #511527;
    font-weight: 800;
}

@media only screen and (max-width: 769px) {
    .asinterior-container {
        display: flex;
        background: #f9f7ef;
        flex-direction: column; /* Corrected typo from 'display' to 'flex-direction' */
        padding: 0px;
    }
    .asinteriorleft-side {
        font-size: 20px; /* Adjusted font size for desktop */
    }
    .left-title{
        font-size: 25px;
        color: #511527;
        font-weight: 800;
        margin-bottom: 5px;
    }
}

/* Your existing styles */

.asinterior-para{
    text-align: justify;
    color: black;
}

/* Mobile styles */
@media (max-width: 768px) {
    .asinterior-container {
        flex-direction: column;
        padding: 1%;
    }

    .asinteriorleft-side,
    .asinteriorright-side {
        width: 100%; /* Ensure full width on mobile */
       padding: 1%;
    }
    .primary-btnknowmore{
        width: 50%;
    }
}

@media (min-width:850px) and (max-width:992px) {
    .left-title{
        font-size: 30px;
    }
}

@media (min-width:800px) and (max-width:1200px) {

.primary-btnknowmore {
    display: inline-block;
    padding: 12px 0px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #c7cacf;
    color: #fff;
   
    margin: 0px 15px 70px 3px;
    width: 60%; /* Full width for desktop */
}

}