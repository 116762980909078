

.whatwedo-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Acta Display W01 Medium;

  
}

.title {
  color: #511527;
  font-size: 40px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-family: Acta Display W01 Medium;

}

.boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.boxes {
  width: calc(50% - 20px);
  margin-bottom: 20px;
  padding: 15px;
  
  box-sizing: border-box;
}

.line {
  height: 2px;
  background-color: #ccc;
  margin: 10px 0;
}



/* Additional Styling for Box Content */
.whatwe-do-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-size: 28px;
  color: #511527;
}

.boxes p {
  font-size: 1em;
  line-height: 1.6;
  color: #511527;
}



.mini-title {
  font-size: 1em;
  font-weight: bold;
  color: #511527;
  margin-bottom: 10px;
}



@media (max-width: 768px) {
  .boxes {
    width: 100%;
  }
  .boxes {
    margin-bottom: 0px;
    padding: 15px;
  }
  .whatwe-do-title {
    
    font-size: 18px;
  font-weight: bold;
  }
  
  
  
}

