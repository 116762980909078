/* Footer.css */

.quick-link-title{
  text-align: center;
}
.footer-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#c7cacf;
    color: black;
    font-size: 18px;
    font-family: Acta Display W01 Medium;
    
   
  }
  .column a{
    text-decoration: none;
    color: inherit;
  }
  
  .column {
    flex: 1;
    /* align-items: center;
    justify-content: center; */
  }
  
  .logo {
    width: 300px;
    height: 90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  
  .sub-column {
    display: flex;
    flex-direction: column;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  h3 {
    /* color: #333; */
    /* text-align: center; */
    margin-left: 10px;
    font-weight: 500;
    font-family: Acta Display W01 Medium;
  }
  
  .divider {
    border: 0;
    border-top: 1px dashed #050102; /* Change to dashed line */
   
    width: 100%; /* Set the desired width */
  }
  
  
  @media (min-width: 768px) {
    /* Add additional styles for larger screens if needed */
    .footer-container {
      flex-direction: row;
    }
  
    .column {
      flex: 1;
      padding: 20px;
    }

  }
  
  /* Updated styles for second column in Footer.css */

.quick-links {
    display: flex;
    flex-direction: row;
  }
  
  .sub-column {
    flex: 1;
    padding: 0 10px; /* Adjust spacing between sub-columns */
  }
  
  @media (max-width: 767px) {
    .quick-links {
      flex-direction: row;
    }

    .logo {
      width: 280px;
      height: 75px;
      display: block;
      margin-left: 10px;
      margin-right: auto;
      margin-top: 20px;
    }

    .footer-para{
      margin-left: 10px;
    }

    #footer-icons a i {
   
  font-size: 30px;
 
 
    }
    #footer-icons{
      margin-right: 0px;
      
     
    }
    .divider-footer {
      border: 0;
      border-top: 1px dashed #050102; /* Change to dashed line */
      margin: 2px;
      width: 100%; /* Set the desired width */
    }


  }
  
  /* Updated styles for second column in Footer.css */

.sub-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sub-column li {
    padding-left: 20px; /* Adjust the spacing between ">" and text */
    position: relative;
    text-align: left;
    padding-bottom: 0px;
  }
  
  .sub-column li::before {
    content: '>';
   
    left: 0;
  }
  
#footer-icons{
  text-align: center;
  color: black;
  font-size: 50px;
  margin-top: 10px;
  padding: 20px;
  padding-bottom: 0px;
  margin-right: 30px;
}

@media (max-width: 768px) {
  #footer-icons{
    text-align: center;
    color: black;
    font-size: 40px;
    margin-top: 10px;
    padding: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
  }
}
#footer-icons a i {
   padding: 5px;
  
  margin-right: 20px;
  background-color: transparent;
} 

.sub-column li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-weight: 300;
  padding-right: 12px;
  transition: 0.15s; /* corrected */
}

#footer-icons a i:hover{
color: #6d4b0c;
transform: scale(1.2);
}
.footer-container a{
  transition: transform 0.3s ease-in-out;

}




#footer-icons a i {
  transition: transform 0.3s ease-in-out;
}

.Address-para::before{
  content: "\f105";
  font-family: "FontAwesome";
  font-weight: 300;
  margin-left: 10px;
  padding-right: 12px;
  transition: 0.15s;
  white-space: nowrap;
}

.Address-para a:hover {
  color: #551aea;
}


@media (max-width: 768px){
  .Address-para{
    padding-left: 10px;
    margin-left: 10px;
  }
  .Address-para::before{
    content: "\f105";
    font-family: "FontAwesome";
    font-weight: 300;
    margin-left: 10px;
    padding-right: 12px;
    transition: 0.15s;
    white-space: nowrap;
  }
  .quick-link-title{
    text-align: left;
  }
  
  
}


@media (min-width:786px) and (max-width:992px) {
  #footer-icons{
    text-align: center;
    color: black;
    font-size: 30px;
    margin-top: 10px;
    padding: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
  }


}

@media (min-width: 992px) and (max-width: 1500px){
  #footer-icons{
    text-align: center;
    color: black;
    font-size: 40px;
    margin-top: 10px;
    padding: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
  }
}