.callus-container {
  display: flex;
  flex-wrap: wrap; /* Allow boxes to wrap to the next line on smaller screens */
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  padding-top: 100px;
  font-family: Acta Display W01 Medium;

  /* background-color: #c4b374; */
}

.box {
  text-align: center;
  border: 1px solid;
  margin: 10px; /* Add margin for spacing between boxes */
  background-color: white;
  padding: 20px; /* Reduce padding for smaller screens */
  flex: 1; /* Take up equal space in the container */
}

.icon {
  font-size: 44px;
  margin-bottom: 10px;
  color: #5d2303;
}

.box p {
  color: #100b0c;
  font-size: 12.5px;
}

.box h4 {
  color: #0d0d0c;
  font-size: 18px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .callus-container {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
  }

  .box {
    width: 90%;
  }
}

/* call us para */
.callus-para {
  text-align: left;
  margin: 0;
}

.boxno-3 {
  padding: 16px;
}

/* .boxno-2{
    padding: 35px;
  } */

.box h4 a:hover {
  color: #c4b374;
  text-decoration: ;
}

.icon:hover {
  color: #c4b374;
}

/* ... (existing styles) ... */

.box h4 a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
