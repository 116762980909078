/* LandscapeDesignPage.css */

.landscape-design-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .center-content {
    text-align: center;
  }
  
  .coming-soon {
    font-family: 'Arial', sans-serif; /* Use any desired font */
    color: #292c29; /* Use any desired color */
    font-size: 3rem; /* Adjust font size as needed */
  }
  