/* Add your styles for StickyIcons here */
.sticky-icons-container {
  position: fixed;
  top: 52%;
  transform: translateY(-50%);
  left: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Set a high z-index value to ensure it's on top */
}

.whatsapp-icon,
.call-icon {
  font-size: 24px; /* Adjust the font size as needed */
  margin-right: 10px; /* Add margin between icons */
  margin-top: 5px;
  z-index: 1001; /* Set a higher z-index value for icons */
 
}

/* Additional styling for individual icons */
.whatsapp-icon {
  color: white; /* WhatsApp color */
  background: #5d2303;
  width: 40px;
  text-align: center;
  border-radius: 5px;
  
}

.call-icon {
  color: white; /* Call icon color */
  width: 40px;
  text-align: center;
  background: #5d2303;
  border-radius: 5px;
  text-decoration: none;

}
.call-icon a,
.whatsapp-icon a {
  color: white;
}