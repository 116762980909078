/* InteriorDesignPage.css */
.interior-design-page {
  display: flex;
  margin-top: 80px;
  font-family: 'Acta Display W01 Medium';

  
}

/* InteriorDesignPage.css */
.interior-design-page {
  display: flex;
  margin-top: 80px;
}

.sidebar {
  margin-top: 0.1%;
  width: 20%;
  position: fixed;
  height: 40%;
  overflow-y: auto;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
  cursor: pointer;
  background-color: #9a9393;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  margin-top: 10%;
  text-align: center;
  border-radius: 10px;

}
.sidebar a {
  text-decoration: none;
  color: black;
  font-weight: bold;

}

.sidebar li.active {
  background-color: #5d2303;
  color: rgb(245, 252, 245);
}



.content {
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  overflow-y: auto;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.project img {
  max-width: 100%;
  height: 100%; /* Set height to 100% to fill the entire box */
  object-fit: cover; /* Cover the entire box while maintaining aspect ratio */
}


.project p {
  margin-top: 10px;
  text-align: center;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 1; /* Always visible */
}

.project p {
  text-align: center;
  font-family: Acta Display W01 Medium;
  color: rgb(6, 6, 6);
  font-size: 25px;
  font-weight: bold;
  backdrop-filter: blur(8px);

}


.project .project-overlay {
  opacity: 1;
}

/* Mobile Responsive Code */
@media (max-width: 768px) {
  .interior-design-page {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    height: auto;
    overflow-y: visible;
  }
  .sidebar li{
    margin-top: 2%;
  }

  .content {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    overflow-y: visible;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }
 
  
  
}


.content {
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  overflow-y: auto;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.project img {
  max-width: 100%;
  height: 100%; /* Set height to 100% to fill the entire box */
  object-fit: cover; /* Cover the entire box while maintaining aspect ratio */
}


.project p {
  margin-top: 10px;
  text-align: center;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project:hover .project-overlay {
  opacity: 1;
}

/* Mobile Responsive Code */
@media (max-width: 768px) {
  .interior-design-page {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    height: auto;
    overflow-y: visible;
  }

  .content {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    overflow-y: visible;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }
}


@media (min-width:850px) and (max-width:992px) {
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .interior-design-page {
    flex-direction: column;
  }



  .content {
    display: block;
   margin-left: auto;
   margin-right: auto;
    width: 60%;
    height: 100%;
    margin-left: 20%;
    padding: 10px;
    overflow-y: visible;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }
  .interior-design-page {
    margin-bottom: 60px;
    
  }
}
