.thank-you-page {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  
  .thank-you-page h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .thank-you-page p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .thank-you-page button {
    background-color: #511527;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .thank-you-page button:hover {
    background-color: #45a049;
  }
  