body {
    margin: 0;
    background: #EEE;
    user-select: none;
    font-family: sans-serif;
  }
  
  #slider {
    position: relative;
    width: 55%;
    height: 40vw;
    margin: 40px auto;
    font-family: 'Helvetica Neue', sans-serif;
    perspective: 1400px;
    transform-style: preserve-3d;
  }
  
  input[type=radio] {
    position: relative;
    top: 108%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin: 0 15px 0 0;
    opacity: 0.4;
    transform: translateX(-83px);
    cursor: pointer;
  }
  
  input[type=radio]:nth-child(5) {
    margin-right: 0px;
  }
  
  input[type=radio]:checked {
    opacity: 1;
  }
  
  #slider label,
  #slider label img {
    position: absolute;
    width:95%;
    height: 95%;
    left: 17px;
    top: 32px;
    color: white;
    font-size: 70px;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 400ms ease;
  }
  
  /* Slider Functionality */
  /* Active Slide */
  #s1:checked ~ #slide1,
  #s2:checked ~ #slide2,
  #s3:checked ~ #slide3,
  #s4:checked ~ #slide4,
  #s5:checked ~ #slide5 {
    box-shadow: 0 13px 26px rgba(0,0,0, 0.3), 0 12px 6px rgba(0,0,0, 0.2);
    transform: translate3d(0%, 0, 0px);
  }
  
  /* Next Slide */
  #s1:checked ~ #slide2,
  #s2:checked ~ #slide3,
  #s3:checked ~ #slide4,
  #s4:checked ~ #slide5,
  #s5:checked ~ #slide1 {
    box-shadow: 0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2);
    transform: translate3d(20%, 0, -100px);
  }
  
  /* Next to Next Slide */
  #s1:checked ~ #slide3,
  #s2:checked ~ #slide4,
  #s3:checked ~ #slide5,
  #s4:checked ~ #slide1,
  #s5:checked ~ #slide2 {
    box-shadow: 0 1px 4px rgba(0,0,0, 0.4);
    transform: translate3d(40%, 0, -250px);
  }
  
  /* Previous to Previous Slide */
  #s1:checked ~ #slide4,
  #s2:checked ~ #slide5,
  #s3:checked ~ #slide1,
  #s4:checked ~ #slide2,
  #s5:checked ~ #slide3 {
    box-shadow: 0 1px 4px rgba(0,0,0, 0.4);
    transform: translate3d(-40%, 0, -250px);
  }
  
  /* Previous Slide */
  #s1:checked ~ #slide5,
  #s2:checked ~ #slide1,
  #s3:checked ~ #slide2,
  #s4:checked ~ #slide3,
  #s5:checked ~ #slide4 {
    box-shadow: 0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2);
    transform: translate3d(-20%, 0, -100px);
  }
  
  @media only screen and (max-width: 768px) {
    body {
      margin: 0;
      background: #EEE;
      user-select: none;
      font-family: sans-serif;
    }
  
    #slider {
      width: 85%; /* Adjusted width for better responsiveness */
      height: 50vw; /* Adjusted height for better display on mobile */
      margin: 10px auto; /* Reduced margin for better centering */
    }
  
    input[type=radio] {
        margin-top: 20px;
      top: 105%; /* Adjusted position for better alignment */
      width: 12px; /* Reduced width for smaller screens */
      height: 12px; /* Reduced height for smaller screens */
      margin: 0 7px 0 0; /* Reduced margin for smaller screens */
      transform: translateX(-65px); /* Adjusted position for smaller screens */
    }
    #slider label,
    #slider label img {
      position: absolute;
      width:75%;
      height: 95%;
      left: 0;
      top: 40px;
      margin-left: 35px;
      color: white;
      font-size: 70px;
      font-weight: bold;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 400ms ease;
    }
  }
  




  