

/* EnquiryForm.css */

.form-page-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999; /* Ensure the form appears above other content */
    font-family: Acta Display W01 Medium;

}

.form-page-content.submitted {
    transform: scale(0.5);
    opacity: 0.5;
    transition: all 1s ease; /* Transition for size reduction */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.form-element {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
    width: calc(100% - 20px); /* Adjust width for padding */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button[type="submit"] {
    background-color: #c7cacf;
    color: black;
    font-weight: 800;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

button[type="submit"]:hover {
    background-color: #45a049;
}

/* adddtional style for your form is submitted */

.form-box {
    transition: all 0.5s ease;
}

.form-submitted {
    transform: scale(0.5);
    opacity: 0.5;
}

.form-submitted-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.form-submitted-indicator i {
    color: green;
    font-size: 50px;
}

.form-submitted-indicator p {
    margin-top: 10px;
}




.cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .cancel-button span {
    font-size: 24px; /* Adjust the font size as needed */
  }  

  @media (max-width: 768px) {
    .form-page-content {
        width: 90%;
      height: 510px;
      
        /* position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 400px;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 9999;  */
    }}
    
    .background-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
        z-index: 999; /* Ensure it appears above other content */
        backdrop-filter: blur(20px); /* Add blur effect to background content */
    }
    body.form-open {
        overflow: hidden;
      }
      
      .form-page-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 400px;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 9999; /* Ensure the form appears above other content */
      }
      .equiry-form{
        display: flex;
        justify-content: center;
        align-items: center;
      }