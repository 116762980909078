/* Welcometo.css */

/* Common styles for all screen sizes */
.welcometo-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    
    margin: 0 auto;
    padding: 20px;
    font-family: Acta Display W01 Medium;
    
   
  }
  
  
  .welcometo-left {
    position: relative;
    z-index: 2; /* Ensure the left box is above the image */
    width: 650px;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid;
    margin-right: 20px;
    margin-top: 100px;
    margin-right: -800px;
    z-index: 1;
    background-color: white;
    padding: 20px;
    
  }
  
  .headingtitle {
    font-size: 15px;
    color: #511527;
    text-align: left;
  }
  
  .text-content .title {
    font-size: 28px;
    color: #511527;
  }
  
  .para-content p {
    font-size: 18px;
    color: black;
  }
  
  .primary-btn-custom {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #c7cacf;
    color: #fff;
    border-radius: 5px;
    margin: 20px 7px 0px 0px;
    padding: 15px 20px;
  }

  
  
  .primary-btn-custom span {
    width: 90px;
    height: 20px;
    color: black;
    font-size: 18px;
  }
  
  .primary-btn-custom::after {
    content: '\00a0→';
    margin-left: 10px;
    color: black;
  }
  
  .primary-btn-custom:hover {
    background-color: #bb9c21;
  }
  
  .divider {
    border-top: 2px solid #8f7b0a;
    margin: 20px 0;
    width: 80px;
  }
  
  .full-attachment {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Responsive styles for screens with a maximum width of 767px (typical for mobile devices) */
  @media (max-width: 767px) {
    .welcometo-container {
      flex-direction: column;
    }
  
    .welcometo-left {
      width: 100%; /* Take full width on mobile */
      height: auto; /* Auto height on mobile */
      margin-top: 0; /* Reset top margin on mobile */
    }
    .image-content img {
      max-width: 100%; /* Make the image responsive */
      max-height: 400px;
      height: auto; /* Maintain aspect ratio */
      margin-top: 10px;
      z-index: 1000;
    }
    
     
  
    /* Add any additional styles needed for mobile */
  }
  
  /* Add this to your Welcometo.css file */
.image-content img {
  max-width: 100%;
  height: 650px;
}





@media (min-width: 768px) and (max-width: 852px) {

  .welcometo-container {
    display: flex;
    justify-content: space-between;
    max-width: 90%; /* Adjust max-width to fit within the tablet width */
    margin: 0 auto;
    padding: 20px;
    font-family: 'Acta Display W01 Medium';
  }
  
  .welcometo-left {
    position: relative;
    z-index: 2;
    width: 90%; /* Adjust width to fit within the tablet width */
    height: auto; /* Adjust height to auto for responsive height */
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid;
    margin-right: 0; /* Remove negative margin */
    margin-top: 50px; /* Reduce top margin */
    background-color: white;
    padding: 20px;
  }
  
  .headingtitle {
    font-size: 14px; /* Adjust font size */
    color: #511527;
    text-align: left;
  }
  
  .text-content .title {
    font-size: 24px; /* Adjust font size */
    color: #511527;
  }
  
  .para-content p {
    font-size: 16px; /* Adjust font size */
    color: black;
  }
  
  .primary-btn-custom {
    display: inline-block;
    padding: 10px 15px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #c7cacf;
    color: #fff;
    border-radius: 5px;
    margin: 15px 7px 0px 0px; /* Adjust margins */
  }

  .primary-btn-custom span {
    width: 70px; /* Adjust width */
    height: 18px; /* Adjust height */
    font-size: 16px; /* Adjust font size */
  }
  
  .primary-btn-custom::after {
    content: '\00a0→';
    margin-left: 8px; /* Adjust margin */
  }
  
  .divider {
    border-top: 2px solid #8f7b0a;
    margin: 15px 0; /* Adjust margin */
    width: 70%; /* Adjust width */
  }
  
  .full-attachment {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}




  
@media (min-width: 800px) and (max-width: 1500px){
 
  .welcometo-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    margin: 0 auto;
    padding: 20px;
    font-family: Acta Display W01 Medium;
    
   
  }
  
  
  .welcometo-left {
    position: relative;
    z-index: 1000; /* Ensure the left box is above the image */
    width: 65%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid;
    margin-right: 20px;
    margin-top: 100px;
    margin-right: -25%;
    z-index: 1;
    background-color: white;
    padding: 20px;
    
  }
  
 
  
  
  .full-attachment {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .image-content img{
    z-index: 1000;
  }
  
}


