/* Map.css */

.map-container {
  display: flex;
 padding-right: 100px;
 padding-left: 100px;
  font-family: Acta Display W01 Medium;
  padding-top: 50px;
}

.left-sectionmap {
  width: 47%;
 padding: 50px;
  background: #fff;
  color: #5d2303;
 
}
.left-sectionmap h2{
  font-size: 35px;
}
.left-sectionmap p{
  font-size: 18px;
}

.right-sectionmap {
  width: 50%;
 padding: 50px;
   
}

.form-box {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.input-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-left: 30px;
}

.button {
  background-color: #511527;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.google-map {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .map-container {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .left-sectionmap,
  .right-sectionmap {
    width: 100%;
    padding: 0px;
  }

  .left-sectionmap h2 {
    font-size: 28px;
  }

  .left-sectionmap p {
    font-size: 16px;
  }

  .right-sectionmap {
    padding: 10%;
    height: 350px;
    z-index: -1;
  }

  .form-box {
    padding: 20px;
  }
 
}

/* para style left part */
.create-your-para{
  color: black;
}

/* desktop view in devices */

@media (min-width:850px) and (max-width:992px) {
  .left-sectionmap,.right-sectionmap{
    padding: 0px;

  }
  .form-box {
    margin-top: 20px;
    margin-bottom: 200px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
}



